<template>
  <div style="display: flex; flex-direction: column; padding: 4px" @mouseover="$emit('mouseover')" @mouseleave="$emit('mouseleave')">
    <div class="info-div">
      <p>{{ companyName }} | {{ vehicle.vehicleName }}</p>
    </div>
    <div v-if="vehicle.deviceEldTypeLabel" class="info-div">
      <p>{{ vehicle.deviceEldTypeLabel }}</p>
    </div>
    <div class="info-div" style="margin-top: 2px">
      <p>
        {{ driverFullName }} |
        <v-flex v-if="phone" class="phone" style="display: inline-block">
          <CRIcon
            :height="20"
            :width="20"
            color="primary"
            style="vertical-align: middle; margin-top: 4px"
          >
            phone
          </CRIcon>
          {{ phone }}
        </v-flex>
      </p>
    </div>
    <div v-if="vehicle.lastSyncTime" class="info-div">
      <p>
        Last report time:
        {{
          lastReportTimeToString(vehicle.lastSyncTime)
        }}
      </p>
    </div>
    <div v-if="etas.length > 0" class="info-div">
      <div v-for="(eta, idx) in etas" :key="idx" class="info-div">
        <p class="info-title">Stop {{ eta.stopNumber }} ETA</p>
        <p>{{ etaToString(eta) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import {
  isoToString,
  secondsToDaysAndHoursStringShortened,
  ianaZoneToOffsetName,
  timeAgoInHoursAndMinutes,
  numDaysBetweenDateTimes
} from '@/utils/time'

export default {
  props: {
    reservationId: { type: Number, required: true },
    vehicle: { type: Object, default: () => {} },
    currentDriver: { type: Object, default: () => {} },
    companyName: { type: String, required: true },
    etas: { type: Array, default: () => [] },
    timezone: { type: String, default: 'America/New_York' },
  },
  computed: {
    phone() {
      const { phone = 'No Phone Number' } = this.currentDriver || {}
      return `${phone}`
    },
    driverFullName() {
      const { firstName = 'No Driver', lastName = 'Assigned' } =
        this.currentDriver || {}
      return `${firstName} ${lastName}`
    },
  },
  methods: {
    isoToString,
    lastReportTimeToString(time) {
      const datetime = DateTime.fromISO(time, { zone: this.timezone })
      let result = datetime.toFormat('M/dd • t ZZZZ')
      if (numDaysBetweenDateTimes(datetime.toJSDate(), new Date()) < 1) {
        const ago = timeAgoInHoursAndMinutes(time)
        result += ` (${ago})`
      }
      return result
    },
    etaToString(eta) {
      const { arrivalTime, duration, timeZone } = eta
      if (arrivalTime && duration && timeZone) {
        return `
        ${arrivalTime.toLocaleString(DateTime.TIME_SIMPLE)}
        ${ianaZoneToOffsetName(timeZone)},
        ${secondsToDaysAndHoursStringShortened(duration)}`
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.info-div {
  padding-bottom: 4px;
}
.info-title {
  color: $gray-medium-dark;
}
.phone {
  display: flex;
  color: $primary;
  align-items: flex-start;
}
</style>
