import { DateTime } from 'luxon'
import { convertISOTimeToDisplayTime } from '@/utils/string'
import { tripById } from '@/services/trips'
import { toTitleCase } from '@/utils/string'
const MILES_PER_METER = 0.00062137

export const getReservationLocalStartDatetime = (reservation) => {
  if (reservation.trip.stops[0].pickupDatetime) {
    const pickupDatetime = reservation.trip.stops[0].pickupDatetime
    const localTimeZone = reservation.trip.stops[0].address.timeZone
    return DateTime.fromISO(pickupDatetime, { zone: localTimeZone }).toISODate()
  } else {
    const localTimeZone = reservation.trip.stops[0].address.timeZone
    return DateTime.fromISO(reservation.trip.startDate, {
      zone: localTimeZone,
    }).toISODate()
  }
}

export const getReservationLocalEndDatetime = (reservation) => {
  const firstStopTimeZone = reservation.trip.stops[0].address.timeZone
  if (
    reservation.trip.stops[reservation.trip.stops.length - 1].dropoffDatetime
  ) {
    const lastStop = reservation.trip.stops[reservation.trip.stops.length - 1]
    const dropoffDatetime = lastStop.dropoffDatetime
    const timeZone = lastStop.address.timeZone
    return DateTime.fromISO(dropoffDatetime, { zone: timeZone }).toISODate()
  } else if (reservation.trip.endDate) {
    return DateTime.fromISO(reservation.trip.endDate, {
      zone: firstStopTimeZone,
    }).toISODate()
  } else {
    return DateTime.fromISO(reservation.trip.startDate, {
      zone: firstStopTimeZone,
    }).toISODate()
  }
}

export const getReservationLocalStartTime = (reservation) => {
  let output
  if (reservation.trip.stops[0].pickupDatetime) {
    const pickupDatetime = reservation.trip.stops[0].pickupDatetime
    const localTimeZone = reservation.trip.stops[0].address.timeZone
    output = DateTime.fromISO(pickupDatetime, {
      zone: localTimeZone,
    }).toISOTime()
  } else {
    const localTimeZone = reservation.trip.stops[0].address.timeZone
    output = DateTime.fromISO(reservation.trip.startDate, {
      zone: localTimeZone,
    }).toISOTime()
  }
  return convertISOTimeToDisplayTime(output)
}

export const getReservationLocalPickupTime = (reservation) => {
  let output
  if (reservation.trip.stops[0].pickupDatetime) {
    const pickupDatetime = reservation.trip.stops[0].pickupDatetime
    const localTimeZone = reservation.trip.stops[0].address.timeZone
    output = DateTime.fromISO(pickupDatetime, {
      zone: localTimeZone,
    }).toFormat('t ZZZZ')
  } else {
    const localTimeZone = reservation.trip.stops[0].address.timeZone
    output = DateTime.fromISO(reservation.trip.startDate, {
      zone: localTimeZone,
    }).toFormat('t ZZZZ')
  }
  return output
}

export const getReservationLocalDropoffTime = (reservation) => {
  let output
  if (
    reservation.trip.stops[reservation.trip.stops.length - 1].dropoffDatetime
  ) {
    const dropoffDatetime =
      reservation.trip.stops[reservation.trip.stops.length - 1].dropoffDatetime
    const localTimeZone =
      reservation.trip.stops[reservation.trip.stops.length - 1].address.timeZone
    output = DateTime.fromISO(dropoffDatetime, {
      zone: localTimeZone,
    }).toFormat('t ZZZZ')
  } else {
    const localTimeZone =
      reservation.trip.stops[reservation.trip.stops.length - 1].address.timeZone
    output = DateTime.fromISO(reservation.endDate, {
      zone: localTimeZone,
    }).toFormat('t ZZZZ')
  }
  return output
}

export const getReservationLocalEndTime = (reservation) => {
  let output
  const firstStopTimeZone = reservation.trip.stops[0].address.timeZone
  if (
    reservation.trip.stops[reservation.trip.stops.length - 1].dropoffDatetime
  ) {
    const lastStop = reservation.trip.stops[reservation.trip.stops.length - 1]
    const dropoffDatetime = lastStop.dropoffDatetime
    const timeZone = lastStop.address.timeZone
    output = DateTime.fromISO(dropoffDatetime, { zone: timeZone }).toISOTime()
  } else if (reservation.trip.endDate) {
    output = DateTime.fromISO(reservation.trip.endDate, {
      zone: firstStopTimeZone,
    }).toISOTime()
  } else {
    output = DateTime.fromISO(reservation.trip.startDate, {
      zone: firstStopTimeZone,
    }).toISOTime()
  }
  return convertISOTimeToDisplayTime(output)
}

export const getReservationFirstStopCity = (reservation) => {
  const { city } = reservation.trip.stops[0].address
  return city
}

export const getReservationLastStopCity = (reservation) => {
  const { city } = reservation.trip.stops[
    reservation.trip.stops.length - 1
  ].address
  return city
}

export const getUnassignedVehicles = (reservation) => {
  let unassignedVehicles = []
  for (const rv of reservation.requiredVehicles) {
    let unassignedCount =
      rv.quantity -
      reservation.vehicleAssignments.filter(
        (va) => va.vehicleTypeId === rv.vehicleType.id
      ).length
    if (unassignedCount) {
      unassignedVehicles.push({
        vehicleTypeId: rv.vehicleType.id,
        quantity: unassignedCount,
      })
    }
  }
  return unassignedVehicles
}

export const paymentStatusTypeMap = {
  not_paid: 'Not Paid',
  partially_paid: 'Partially Paid',
  fully_paid: 'Fully Paid',
}
export const referralStatusTypeMap = {
  offered: 'Offered',
  accepted: 'Accepted',
  rejected: 'Rejected',
  not_offered: 'Not Offered',
  fully_offered: 'Fully Offered',
  fully_accepted: 'Fully Accepted',
  fully_confirmed: 'Fully Confirmed',
  confirmed: 'Confirmed',
}

export const reservationStatusTypeMap = {
  upcoming: 'Upcoming',
  started: 'Started',
  finished: 'Finished',
  cancelled: 'Cancelled',
  hold: 'On Hold',
}

export const cancellationStatusMap = {
  cancelled: 'Cancelled',
  started: 'Cancellation Started',
}

export const cancellationStatusTypeString = (reservation) => {
  if (reservation.reinstateStatusKey === 'started') {
    return `Reinstate Started (${reservation.cancellationClassificationLabel})`
  }
  if (reservation.cancellationClassificationLabel) {
    if (reservation.cancellationStatusKey === 'cancelled') {
      return ` ${reservation.cancellationClassificationLabel}`
    } else if (reservation.cancellationStatusKey === 'started') {
      return `${toTitleCase(reservation.cancellationStatusKey)} (${
        reservation.cancellationClassificationLabel
      })`
    }
  } else {
    return toTitleCase(reservation.cancellationStatusKey)
  }
  return ''
}

export const getReservationItineraryAsString = async (row, full = false) => {
  let tripId = row?.tripId || row?.reservation?.tripId
  const response = await tripById(tripId)
  const trip = response.data

  const managedId = row?.managedId || row?.reservation?.managedId
  const tripType = trip.tripType.label
  const vehicles = trip.tripVehicles
    .map((vehicle) => `${vehicle.vehicleType.label}: ${vehicle.quantity}`)
    .join(' -')
  const drivers = trip.requiredDrivers
  const passengers = trip.passengerCount
  const distance = Math.round(trip.distance * MILES_PER_METER)
  const tripDetails = `${managedId} - ${tripType} - Distance: ${distance} - ${vehicles} - Drivers: ${drivers} - Passengers: ${passengers}`
  const stops = trip.stops.sort((a, b) => a.orderIndex - b.orderIndex)
  const stopString = stops.map((stop) => stopToString(stop, full)).join('\n')
  const itinerary = `${tripDetails}\n\n${stopString}`
  return itinerary
}

const stopToString = (stop, full) => {
  const number = stop.orderIndex + 1
  let address = ''

  if (full) {
    if (stop.address.title) {
      address = stop.address.title + '\n'
    }

    if (stop.address.name) {
      address = address + stop.address.name
    }
  } else {
    address = `${stop.address.city}, ${stop.address.state} ${stop.address.postalCode}`
  }

  let dropoff = formatStopTime(stop.dropoffDatetime, stop.address.timeZone)
  let pickup = formatStopTime(stop.pickupDatetime, stop.address.timeZone)
  dropoff = dropoff ? `Drop-off: ${dropoff}` : ''
  pickup = pickup ? `Pickup: ${pickup}` : ''
  let times = dropoff ? `${dropoff}\n` : ''
  times = pickup ? `${times}${pickup}\n` : times
  return `Stop ${number} - ${address}\n${times}`
}

const formatStopTime = (datetime, timeZone) => {
  if (!datetime) {
    return ''
  }
  return DateTime.fromISO(datetime, { zone: timeZone }).toFormat('M/dd/yyyy t')
}
